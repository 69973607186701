import React from "react";
import { graphql, PageProps } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { Page } from "../components/Page";
import { Container } from "../components/Container";
import { Header } from "../components/Header";
import "../theme/prism/prism-one-dark.css";
import { ScrollToc, Toc } from "../components/scrollToc";
import SEO from "../components/Seo";

const Template = ({ data }: PageProps<Queries.BlogTemplateQuery>) => {
  return (
    <Page>
      <SEO title={data.mdx?.frontmatter?.title || ""} />
      <Header />
      <Container>
        <ScrollToc tableOfContents={data.mdx?.tableOfContents as Toc} />
        <h1>{data.mdx?.frontmatter?.title}</h1>
        <h5>{data.mdx?.frontmatter?.date}</h5>
        <MDXRenderer style={{}}>{data.mdx?.body || ""}</MDXRenderer>
      </Container>
    </Page>
  );
};
export default Template;

export const pageQuery = graphql`
  query BlogTemplate($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      tableOfContents(maxDepth: 2)
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
  }
`;
