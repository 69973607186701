import * as React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { StickyToc, TocHeading } from "./scrollToc.css";

export type TocEntry = {
  url?: string;
  title?: string;
  items?: TocEntry[];
};

export type Toc = {
  items: TocEntry[];
};

function flatten(arr: any[]): any[] {
  return arr.reduce(
    (flat, toFlatten) =>
      flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten),
    []
  );
}

function renderToc(tableOfContents: Toc) {
  function step(entry: TocEntry, level = 0): any[] {
    if (!entry) {
      return [];
    }

    return [
      { level, ...entry },
      ...(entry.items
        ? flatten(entry.items.map((e) => step(e, level + 1)))
        : []),
    ];
  }

  return step(tableOfContents);
}

export const ScrollToc: React.FC<{ tableOfContents: Toc }> = ({
  tableOfContents,
}) => {
  const list = renderToc(tableOfContents);
  const urls = list
    .map((l) => l.url && `${l.url}`.replace("#", ""))
    .filter((a) => !!a);

  return !urls.length ? null : (
    <div className={StickyToc}>
      <h2 className={TocHeading}>Table of Contents</h2>
      <ol>
        {list.map((entry, index) => {
          if (!entry.title) {
            return;
          }

          return (
            <li key={`${entry.title}${index}`}>
              {entry.url && entry.title && (
                <AnchorLink
                  href={entry.url}
                  style={{
                    display: "inline-block",
                    // fontSize: {`${(6 - entry.level + 12) / 16}rem`},
                    // paddingLeft: {`${entry.level * 0.5}em`}
                  }}
                  aria-current="page"
                >
                  {entry.title}
                </AnchorLink>
              )}
            </li>
          );
        })}
      </ol>
    </div>
  );
};
